import * as Styled from "./OxNotFound.styled";

import { EInputTheme, OxThemedInput } from "../OxThemedInput";
import { graphql, useStaticQuery } from "gatsby";

import { OxButton } from "../OxButton";
import { OxLink } from "../OxLink";
import React from "react";

export const OxNotFound: React.FC<Record<string, unknown>> = () => {
  const data = useStaticQuery(
    graphql`
      query {
        bgImage: file(relativePath: { eq: "404-Page.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `
  );

  return (
    <Styled.Container>
      <Styled.Label>404 ERROR</Styled.Label>
      <Styled.Header>OOPS!</Styled.Header>
      <Styled.Paragraph>
        The page you’re looking for couldn’t be found.
      </Styled.Paragraph>
      <OxThemedInput theme={EInputTheme.BackgroundGoldAlternative}>
        <OxLink to="/">
          <OxButton>HOME PAGE</OxButton>
        </OxLink>
      </OxThemedInput>
      <Styled.Image
        fluidImages={[data.bgImage.childImageSharp.gatsbyImageData]}
      />
    </Styled.Container>
  );
};
